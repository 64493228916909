/* Modern Amplify Dark Theme */

:root, [data-amplify-theme] {
  --amplify-colors-font-primary: #333;
  /* you can also use references: */
  --amplify-colors-font-secondary: var(--amplify-colors-neutral-60);
}


[data-amplify-authenticator] {
  /* Background colors */
  --amplify-colors-background-primary: rgb(17 24 39); /* gray-900 */
  --amplify-colors-background-secondary: rgb(31 41 55); /* gray-800 */
  
  /* Brand colors */
  --amplify-colors-brand-primary-10: rgb(30 58 138); /* blue-900 */
  --amplify-colors-brand-primary-80: rgb(59 130 246); /* blue-500 */
  --amplify-colors-brand-primary-90: rgb(37 99 235); /* blue-600 */
  --amplify-colors-brand-primary-100: rgb(29 78 216); /* blue-700 */
  
  /* Text colors */
  --amplify-colors-font-primary: rgb(243 244 246); /* gray-100 */
  --amplify-colors-font-secondary: rgb(209 213 219); /* gray-300 */
  --amplify-colors-font-interactive: rgb(59 130 246); /* blue-500 */
  
  /* Border colors */
  --amplify-colors-border-primary: rgb(75 85 99); /* gray-600 */
  --amplify-colors-border-secondary: rgb(55 65 81); /* gray-700 */
  
  /* Component specific colors */
  --amplify-components-fieldcontrol-border-color: rgb(75 85 99);
  --amplify-components-tabs-item-active-color: rgb(59 130 246);
  --amplify-components-tabs-item-focus-color: rgb(59 130 246);
  --amplify-components-button-primary-background-color: rgb(37 99 235);
  --amplify-components-button-primary-hover-background-color: rgb(29 78 216);
}

/* Modern form styling */
.amplify-input {
  background-color: rgb(238, 239, 240) !important; /* gray-800 */
  border: 1px solid rgb(75 85 99) !important; /* gray-600 */
  color: rgb(3, 3, 3) !important; /* gray-100 */
  transition: all 0.2s ease-in-out;
}

.form {
  background-color: brown;
}
.amplify-input:focus {
  background-color: rgb(238, 239, 240) !important; /* gray-800 */
  border-color: rgb(59 130 246) !important; /* blue-500 */
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2) !important;
}

.amplify-input::placeholder {
  color: rgb(128, 128, 128) !important; /* gray-400 */
}

/* Modern button styling */
.amplify-button[data-variation='primary'] {
  background: linear-gradient(to right, rgb(37 99 235), rgb(29 78 216)) !important;
  border: none !important;
  color: white !important;
  font-weight: 500 !important;
  padding: 0.75rem 1.5rem !important;
  transition: all 0.2s ease-in-out !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.amplify-button[data-variation='primary']:hover {
  background: linear-gradient(to right, rgb(29 78 216), rgb(30 58 138)) !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 6px 8px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06) !important;
}

/* Tabs styling */
.amplify-tabs {
  border-color: rgb(55 65 81) !important; /* gray-700 */
  background-color: transparent !important;
}

.amplify-tabs-item {
  color: rgb(209 213 219) !important; /* gray-300 */
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease-in-out !important;
}

.amplify-tabs-item[data-state='active'] {
  color: rgb(59 130 246) !important; /* blue-500 */
  border-color: rgb(59 130 246) !important;
  background-color: transparent !important;
}

/* Card/Container styling */
.amplify-card {
  background-color: rgb(17 24 39) !important; /* gray-900 */
  border: 1px solid rgb(55 65 81) !important; /* gray-700 */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 1rem !important;
}

/* Alert/Error message styling */
.amplify-alert {
  background-color: rgb(31 41 55) !important; /* gray-800 */
  border-color: rgb(239 68 68) !important; /* red-500 */
  color: rgb(252 165 165) !important; /* red-300 */
}

/* Welcome header gradient */
.welcome-header {
  background: linear-gradient(135deg, rgb(30 58 138), rgb(37 99 235)) !important;
  position: relative;
  overflow: hidden;
}

.welcome-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(59, 130, 246, 0.1) 0%, rgba(37, 99, 235, 0.1) 100%);
  z-index: 1;
}

.welcome-text {
  background: linear-gradient(to right, rgb(219 234 254), rgb(243 244 246));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  z-index: 2;
}

/* Form field labels */
.amplify-label {
  color: rgb(156 163 175) !important; /* gray-400 */
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
}

/* Modern loading spinner */
.amplify-loader {
  border-color: rgb(59 130 246) transparent rgb(59 130 246) transparent !important;
}

/* Custom utility classes */
.app-container {
  min-height: 100vh;
  background-color: rgb(249 250 251);
}

.header-container {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
}

.header-content {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.button-primary {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: rgb(37 99 235);
  border: 1px solid transparent;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.button-primary:hover {
  background-color: rgb(29 78 216);
}

.button-primary:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px rgb(255 255 255), 0 0 0 4px rgb(37 99 235);
}

.button-danger {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: rgb(220 38 38);
  border: 1px solid transparent;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.button-danger:hover {
  background-color: rgb(185 28 28);
}

.button-danger:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px rgb(255 255 255), 0 0 0 4px rgb(220 38 38);
}

.table-container {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
  overflow: hidden;
  border: 1px solid rgb(229 231 235);
  border-radius: 0.5rem;
  background-color: white;
}

.table-header {
  background-color: rgb(249 250 251);
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(107 114 128);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.table-cell {
  padding: 1rem 1.5rem;
  white-space: nowrap;
  font-size: 0.875rem;
  color: rgb(107 114 128);
}

.file-link {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgb(37 99 235);
}

.file-link:hover {
  color: rgb(30 64 175);
  text-decoration: underline;
}

.environment-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.125rem 0.625rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
}
